<script setup lang="ts">
import { useSlots } from 'vue'

const props = defineProps<{
  expanded: boolean
}>()

const emit = defineEmits<{
  (event: 'update:expanded', state: boolean): void
}>()

const slots = useSlots()
const hasSlot = (name: string) => !!slots[name]
const isTestEnvironment = import.meta.env.VITE_TEST_ENVIRONMENT === 'true'
</script>

<template>
  <div class="fixed group transition-all duration-300 ease-in-out h-full z-[51]">
    <div :class="[
      'hidden md:fixed md:inset-y-0 md:flex md:flex-col transition-all duration-300 ease-in-out p-3',
      { 'md:w-56': props.expanded },
      { 'md:w-20': !props.expanded }
    ]">
    <button
      @click="emit('update:expanded', !props.expanded)"
      class="absolute flex items-center justify-center w-6 h-6 bg-white rounded-full top-12 right-0 z-[51] opacity-0 group-hover:opacity-100 transition-all duration-300 ease-in-out shadow-bordered text-gray-500 hover:bg-blue-500 hover:text-white cursor-pointer">
      <svg width="24" height="24" viewBox="0 0 24 24" role="presentation" :class="[
        { 'rotate-180': props.expanded }
      ]">
        <path d="M10.294 9.698a.988.988 0 010-1.407 1.01 1.01 0 011.419 0l2.965 2.94a1.09 1.09 0 010 1.548l-2.955 2.93a1.01 1.01 0 01-1.42 0 .988.988 0 010-1.407l2.318-2.297-2.327-2.307z" fill="currentColor" fill-rule="evenodd"></path>
      </svg>
    </button>

      <div :class="[
        'flex min-h-0 flex-1 flex-col bg-gradient-to-r backdrop-blur rounded-lg shadow-bordered transition-all',
        { ' from-white to-white/70': props.expanded },
        { 'from-white to-white': !props.expanded }
      ]">
        <div class="relative flex flex-1 flex-col overflow-y-auto pb-4 overflow-hidden rounded-t-lg">
          <slot name="logo" v-if="hasSlot('logo')" />
          <div class="mt-2">
            <slot name="top" />
          </div>
        </div>

        <RouterLink
          v-if="isTestEnvironment"
          :to="{ name: 'settings.system' }"
          class="bg-red-500 hover:bg-red-600 transition-colors border-gray-100 text-xs py-1.5 text-red-50 text-center font-bold uppercase">
          <span v-t="'Test'" v-show="!props.expanded"/>
          <span v-t="'Test environment'" v-show="props.expanded"/>
        </RouterLink>

        <div
          v-if="hasSlot('bottom')"
          :class="[
            'border-gray-100 py-2',
            { 'border-t': !isTestEnvironment }
          ]">
          <slot name="bottom" />
        </div>
      </div>
    </div>
  </div>
</template>
