import { RouteLocation, RouteRecordRaw } from 'vue-router'
import LocationsChildRoutes from '@/router/measure-locations'
import sectionParamAssessmentRoutes from '@/router/section-param-assessments'

const routes: Array<RouteRecordRaw> = [
  {
    path: 'edit',
    name: 'control-panel.section.edit',
    component: () => import('@/views/control-panel/projects/project/sections/section/Edit.vue'),
  },
  {
    path: 'logbook',
    name: 'section.logbook',
    component: () => import('@/views/control-panel/projects/project/sections/section/Logbook.vue'),
  },
  {
    path: 'water-extraction-summary',
    name: 'section.waterExtractionSummary',
    component: () => import('@/views/control-panel/projects/project/sections/section/WaterExtractionSummary.vue'),
  },
  {
    path: 'measure-locations',
    name: 'measure-locations',
    component: () => import('@/views/control-panel/projects/project/sections/section/MeasureLocations.vue'),
    meta: { title: 'Alle deelprojecten' },
    redirect: (to: RouteLocation) => ({ name: 'map.locations', params: to.params }),
    children: LocationsChildRoutes
  },
  {
    path: 'param-assessments',
    name: 'section.param-assessments',
    component: () => import('@/views/control-panel/projects/project/sections/section/ParamAssessments.vue'),
    redirect: () => ({ name: 'section.param-assessments.list' }),
    children: sectionParamAssessmentRoutes
  },
]

export default routes
