import { createStore } from 'vuex'
import company from '@/store/modules/company'
import user from '@/store/modules/user'
import notifications from '@/store/modules/notifications'
import dialog from '@/store/modules/dialog'
import interactableWindows from '@/store/modules/interactable-windows'
import enums from '@/store/modules/enums'
import auth from '@/store/modules/auth'

export default createStore({
  modules: {
    company,
    user,
    notifications,
    dialog,
    interactableWindows,
    enums,
    auth
  }
})
